<template>
  <b-container class="p-0" fluid>
    <b-row>
      <b-col>
        <KTCard>
          <template v-slot:title>
            &nbsp;
          </template>
          <template v-slot:toolbar>
            <b-input-group-append>
              <b-button
                :class="(filtersApplied ? `btn-danger text-white` : 'btn-light-primary') + ' btn font-weight-bolder ml-2 text-end float-right'"
                v-b-toggle.booking>
                <i class="fa fa-filter fa-sm"></i> Filter
              </b-button>
            </b-input-group-append>
          </template>
          <template v-slot:body>
            <b-row class="mt-5 mb-10">
              <b-col>
                <div class="card card-custom wave wave-animate-slow wave-warning mb-8 mb-lg-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-warning svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"></rect>
                              <rect fill="#000000"
                                    height="13"
                                    opacity="0.3"
                                    rx="1.5"
                                    width="3"
                                    x="12"
                                    y="4"></rect>
                              <rect fill="#000000"
                                    height="8"
                                    opacity="0.3"
                                    rx="1.5"
                                    width="3"
                                    x="7"
                                    y="9"></rect>
                              <path
                                d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                              <rect fill="#000000"
                                    height="6"
                                    opacity="0.3"
                                    rx="1.5"
                                    width="3"
                                    x="17"
                                    y="11"></rect>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.bookings
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Bookings
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="card card-custom wave wave-animate wave-primary mb-8 mb-lg-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-primary svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"/>
                              <rect fill="#000000"
                                    height="12"
                                    opacity="0.3"
                                    rx="2"
                                    width="10"
                                    x="2"
                                    y="2"/>
                              <path
                                d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                fill="#000000"/>
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.total
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Total Revenue
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="card card-custom wave wave-animate-slow wave-success mb-8 mb-lg-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-success svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"/>
                              <path
                                d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z"
                                fill="#000000" opacity="0.3"/>
                              <path
                                d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z"
                                fill="#000000"/>
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.collected
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Collected Amount
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="card card-custom wave wave-animate-fast wave-danger">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-danger svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"/>
                              <path
                                d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,
                          17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,
                          17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
                                fill="#000000"/>
                              <path
                                d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,
                          3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,
                          12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
                                fill="#000000" opacity="0.3"/>
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.pending
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Pending Amount
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-5 mb-10">
              <b-col>
                <div class="card card-custom wave wave-animate-slow wave-warning mb-8 mb-lg-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-warning svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"></rect>
                              <rect fill="#000000"
                                    height="13"
                                    opacity="0.3"
                                    rx="1.5"
                                    width="3"
                                    x="12"
                                    y="4"></rect>
                              <rect fill="#000000"
                                    height="8"
                                    opacity="0.3"
                                    rx="1.5"
                                    width="3"
                                    x="7"
                                    y="9"></rect>
                              <path
                                d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                              <rect fill="#000000"
                                    height="6"
                                    opacity="0.3"
                                    rx="1.5"
                                    width="3"
                                    x="17"
                                    y="11"></rect>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.room_revenue
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Rooms Revenue
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="card card-custom wave wave-animate wave-primary mb-8 mb-lg-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-primary svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"/>
                              <rect fill="#000000"
                                    height="12"
                                    opacity="0.3"
                                    rx="2"
                                    width="10"
                                    x="2"
                                    y="2"/>
                              <path
                                d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                fill="#000000"/>
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.banquet_revenue
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Banquet Revenue
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="card card-custom wave wave-animate-slow wave-success mb-8 mb-lg-0">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-success svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"/>
                              <path
                                d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z"
                                fill="#000000" opacity="0.3"/>
                              <path
                                d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z"
                                fill="#000000"/>
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.average_revenue
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Average Revenue
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="card card-custom wave wave-animate-fast wave-danger">
                  <div class="card-body">
                    <div class="d-flex align-items-center p-5">
                      <div class="mr-6">
                        <span class="svg-icon svg-icon-danger svg-icon-4x">
                          <svg height="24px"
                               version="1.1"
                               viewBox="0 0 24 24"
                               width="24px"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none"
                               fill-rule="evenodd"
                               stroke="none"
                               stroke-width="1">
                              <rect height="24" width="24" x="0" y="0"/>
                              <path
                                d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,
                          17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,
                          17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
                                fill="#000000"/>
                              <path
                                d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,
                          3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,
                          12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
                                fill="#000000" opacity="0.3"/>
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div class="d-flex flex-column">

                        <div class="text-dark font-weight-bolder font-size-h2 mt-3">
                          {{
                            counts.average_revenue_per_plate
                          }}
                        </div>
                        <a class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3"
                           href="#">
                          Per Plate Revenue
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
        </KTCard>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col v-if="revenue_chart_data">
        <Booking :dropdowns="dropdowns" ref="barChart" :filters="filters" :revenue_chart_data="revenue_chart_data" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs lazy>
          <b-tab :active="tab === 'events'" title="Leads"
                 @click="handleChangeTab('events')">
            <ListWidget2 v-if="eventPieChartData"
                         :data="eventPieChartData"
                         :filters-applied="filtersApplied"
                         title="Leads"/>
          </b-tab>
          <b-tab :active="tab === 'sources'" title="Sources"
                 @click="handleChangeTab('sources')">
            <ListWidget2 v-if="sourcesPieChartData"
                         :data="sourcesPieChartData"
                         :filters-applied="filtersApplied"
                         title="Sources"/>
          </b-tab>
          <b-tab v-model="tab"
                 :active="tab === 'drop-analytics'"
                 title="Drop Analytics"
                 @click="handleChangeTab('drop-analytics')">
            <ListWidget2 :data="dropAnalytics"
                         :filters-applied="filtersApplied"
                         title="Drop Analytics"/>
          </b-tab>
          <b-tab v-model="tab"
                 :active="tab === 'event-type'"
                 title="Type of events"
                 @click="handleChangeTab('event-type')">
            <ListWidget2 :data="eventTypes"
                         :filters-applied="filtersApplied"
                         title="Type of events"/>
          </b-tab>
          <b-tab v-model="tab"
                 :active="tab === 'revenue'"
                 title="Revenue"
                 @click="handleChangeTab('revenue')">
            <ListWidget2 :data="revenue"
                         :filters-applied="filtersApplied"
                         title="Revenue"/>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <Filters id="booking"
             :date-disabled="dateDisabled"
             :dropdowns="dropdowns"
             :filters="filters"
             @apply="handleFilter"
             @reset="reset"/>
  </b-container>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import {request} from "@/core/services/Request";
import moment from "moment-timezone";
import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";
import {getAuthUser} from "@/util/globalFunction";
import ListWidget2 from "@/view/content/widgets/dashboard/Widget2.vue";
import Booking from "@/view/content/widgets/dashboard/Booking";
import Filters from "@/view/content/widgets/dashboard/Filters";
import KTCard from "@/view/content/Card";

const FILTER_STATE = {
  event_id: null,
  user_id: null,
  from_date: moment().subtract(6, 'months').format('YYYY-MM-DD'),
  to_date: moment().add(5, 'months').format('YYYY-MM-DD'),
  sub_user_id: null,
  year: null,
  visible: true
};

export default {
  mixins: [totalAmountMixin],
  components: {
    KTCard,
    Booking,
    ListWidget2,
    Filters,
  },
  data() {
    return {
      pie_drop_analytics_number: [],
      filters: {...FILTER_STATE},
      tab: 'events',
      sourcesPieChartData: [],
      dropAnalytics: [],
      eventPieChartData: [],
      eventTypes: [],
      revenue: [],
      revenue_chart_data: {
        values: [],
        keys: []
      },
      filtersApplied: false,
      counts: {
        bookings: 0,
        total: 0,
        collected: 0,
        pending: 0,
        room_revenue: 0,
        banquet_revenue: 0,
        average_revenue: 0,
        average_revenue_per_plate: 0,
      },
      dropdowns: {
        leads: [],
        events: [],
        halls: [],
        sources: [],
        eventType: [],
        subUsers: [],
      }
    };
  },
  async mounted() {


    await Promise.all([

      // this.getRooms(),
      this.getHalls(),
      this.getEventType(),
      this.getDetails()
    ])

  },
  methods: {
    dateDisabled(ymd, date) {
      return false;
    },
    handleChangeTab(tab) {
      this.tab = tab;
    },
    async handleFilter(type, filters) {

      this[type] = filters;
      this.filtersApplied = !_.isEqual(filters, FILTER_STATE);
      await Promise.all([
        this.getDetails(),
        this.$refs.barChart.loadBookingData()
      ])
    },
    reset(type) {

      this[type] = _.clone(FILTER_STATE)
    },
    async getDetails() {

      try {
        const response = await request({
          url: `/dashboard`,
          method: 'post',
          data: {...this.filters},
        });

        const {data} = response.data;

        if (data.pie_charts) {
          this.sourcesPieChartData = data.pie_charts.sources;
          this.dropAnalytics = data.pie_charts.drop_analytics;
          this.eventPieChartData = data.pie_charts.events;
          this.eventTypes = data.pie_charts.event_types;
          this.revenue = data.pie_charts.revenue;
          this.revenue_chart_data = data.revenue_chart_data;
        }

        if (data.counts) {
          this.counts = data.counts
        }
      } catch (e) {

      }
    },

    async getRooms() {
      try {
        const response = await request({
          url: `/dropdowns/rooms`,
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.rooms = data;
      } catch (e) {

      }
    },

    async getHalls() {
      try {
        const response = await request({
          url: `/dropdowns/halls`,
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.halls = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },

    async getSources() {
      try {
        const response = await request({
          url: `/dropdowns/sources`,
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.sources = data.map((item) => {
          return {
            id: item.id,
            label: item.source_name,
          };
        });
      } catch (e) {

      }
    },

    async getEventType() {
      try {
        const response = await request({
          url: '/dropdowns/event/type',
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.eventType = data.map((item) => {
          return {
            id: item,
            label: item,
          };
        });
      } catch (e) {

      }
    },

    async getSubUser() {
      try {
        const response = await request({
          url: `/dropdowns/sub/user`,
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.subUsers = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
  },
  computed: {
    years() {
      const allYear = Array.from({length: new Date().getFullYear() - 2000}, (value, index) => 2020 + index);
      return allYear.map((item) => {
        return {
          id: item,
          label: item,
        };
      });
    },
    greetings() {
      const myDate = new Date();
      const hrs = myDate.getHours();

      let greet;
      let user = getAuthUser();

      if (hrs < 12) {
        greet = this.$t('Good Morning');
      } else if (hrs >= 12 && hrs <= 17) {
        greet = this.$t('Good Afternoon');
      } else if (hrs >= 17 && hrs <= 24) {
        greet = this.$t('Good Evening');
      }
      return `${greet}, ${user.name}`;
    },
    ...mapState([
      'global',
    ]),
    leads() {
      return [
        {
          label: 'Hot',
          value: `${this.hot_count}`
        },
        {
          label: 'Qualified',
          value: `${this.qualified_count}`
        },
        {
          label: 'Standard',
          value: `${this.standard_count}`
        },
        {
          label: 'Won',
          value: `${this.won_count}`
        },
        {
          label: 'Lost',
          value: `${this.lost_count}`
        },
        {
          label: 'Follow Up',
          value: `${this.followUpCount}`
        },
        {
          label: 'Brochure',
          value: `${this.brochureCount}`
        },
      ];
    },
  }
};
</script>

<style lang="scss" scoped>
#count-card {
  background-color: unset;
  background-clip: unset;

  .header-block {
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }

  .card-header {
    color: #fff;
    background-color: #ff7a59;
    font-size: 1.6rem;
    font-weight: 700;
  }

  #revenue, #business {
    height: 35rem !important;
  }

  .card {
    height: 50rem;

    .card-body {
      padding: 0;
      padding-bottom: 40px;

      .amount-numbers {
        font-size: 2.8rem;
        color: #ff7a59;
        margin-bottom: 1.6rem;
      }

      .count-numbers {
        font-size: 1.8rem;
      }
    }
  }
}
</style>
