<template>
    <div>
        <guest-user v-if="$global.hasPermission('masteguestuser') || $global.hasRole('subguestuser')"></guest-user>
        <super-admin v-if="$global.hasPermission('companyprofilesview')"></super-admin>
        <super-user v-if="$global.hasPermission('owncompanyprofilesview')"></super-user>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import GuestUser from "./dashboards/GuestUser";
import SuperAdmin from "./dashboards/SuperAdmin";
import SuperUser from "./dashboards/SuperUser";

export default {
    name: "dashboard",
    components: {
        GuestUser,
        SuperAdmin,
        SuperUser
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
    },
    methods: {
        setActiveTab1(event) {
            this.tabIndex = this.setActiveTab(event);
        },
        setActiveTab2(event) {
            this.tabIndex2 = this.setActiveTab(event);
        },
        /**
         * Set current active on click
         * @param event
         */
        setActiveTab(event) {
            // get all tab links
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set current active tab
            event.target.classList.add("active");

            // set clicked tab index to bootstrap tab
            return parseInt(event.target.getAttribute("data-tab"));
        }
    }
};
</script>
