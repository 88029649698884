<template>
  <b-sidebar
      :backdrop-variant="`transparent`"
      backdrop
      :id="id"
      right
      shadow
      title="Filters"
  >
    <template #header="{ hide }">
      <b-col sm="10">
        <strong id="sidebar-backdrop-leads___title__">Filters</strong>
      </b-col>
      <b-col sm="2">
        <button @click="hide();"
                class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
          <i class="ki ki-close icon-xs text-muted"></i>
        </button>
      </b-col>
    </template>
    <template #header="{ hide }">
      <b-col sm="10">
        <strong id="sidebar-backdrop-bookings___title__">Filters</strong>
      </b-col>
      <b-col sm="2">
        <button @click="hide();"
                class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
          <i class="ki ki-close icon-xs text-muted"></i>
        </button>
      </b-col>
    </template>
    <template #footer="{ hide }">
      <div
          class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
        <b-button @click="hide(); reset()"
                  class="btn btn-light-primary font-weight-bolder ml-2">
          <i class="fa fa-broom fa-sm"></i> Clear All
        </b-button>
      </div>
    </template>
    <div class="px-10 py-4">
      <b-col sm="12">
        <b-form-group
            label="Start Date"
            label-for="from_date">
          <b-form-datepicker
              :date-disabled-fn="dateDisabled"
              :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
              close-button
              today-button
              v-model="filters.from_date"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
            label="End Date"
            label-for="to_date">
          <b-form-datepicker
              :date-disabled-fn="dateDisabled"
              :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
              :disabled="!filters.from_date"
              close-button
              today-button
              v-model="filters.to_date"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
            label="Hall"
            label-for="halls">
          <treeselect
              :multiple="true"
              :options="dropdowns.halls"
              v-model="filters.halls">
          </treeselect>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
            label="Event type"
            label-for="event_type">
          <treeselect
              :multiple="true"
              :options="dropdowns.eventType"
              v-model="filters.event_type"
          >
          </treeselect>
        </b-form-group>
      </b-col><!--/b-col-->
    </div>
  </b-sidebar>
</template>
<script>
export default {
  name: 'Filters',
  props: {
    dateDisabled: {},
    dropdowns: {},
    filters: {
      from_date: {
        default: function () {
          return null
        }
      },
      to_date: {
        default: function () {
          return null
        }
      }
    },
    id: {}
  },
  methods: {
    reset() {
      this.$emit('reset',`filters`)
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        this.$emit('apply',`filters`, val)
      },
      deep: true
    }
  }
}
</script>
