<template>
    <div>
        <div class="row m-0" v-if="events">
            <div class="col-12">
                <div class="col bg-white px-6 py-8 mb-7 text-center">
                    <span class="svg-icon svg-icon-3x svg-icon-info d-block text-center font-size-h1">
                        <Countdown :deadline="getEventCountDown(events.event_from_date)"></Countdown>
                    </span>
                    <a href="javascript:;">
                        <h3>{{events.title}} Event Timeline</h3>
                    </a>
                </div>
            </div>
        </div>
        <div class="row m-0" v-if="guests">
            <div class="col-12">
                <h3>Guests</h3>
            </div>
            <div class="col-4">
                <b-card class="border-info">
                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2 font-size-h1">
                        {{guests.guest_count}}
                        <i class="fa fa-users"></i>
                    </span>
                    <router-link :to="{name:'guests'}" class="text-info font-weight-bold font-size-h6">
                        Total Guests
                    </router-link>
                </b-card>
            </div>
            <div class="col-4">
                <b-card class="border-success">
                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2 font-size-h1">
                        {{guests.guest_invitation_accept_count}}
                        <i class="fa fa-book"></i>
                    </span>
                    <router-link :to="{name:'guests'}" class="text-success font-weight-bold font-size-h6">
                        Guest Invitation Accept
                    </router-link>
                </b-card>
            </div>
            <div class="col-4">
                <b-card class="border-danger">
                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2 font-size-h1">
                        {{guests.guest_invitation_decline_count}}
                        <i class="fa fa-book"></i>
                    </span>
                    <router-link :to="{name:'guests'}" class="text-danger font-weight-bold font-size-h6">
                        Guest Invitation Declined
                    </router-link>
                </b-card>
            </div>
        </div>
        <div class="row m-0 mt-5" v-if="tasks">
            <div class="col-12">
                <h3>Tasks</h3>
            </div>
            <div class="col-4">
                <b-card class="border-dark">
                    <span class="svg-icon svg-icon-3x svg-icon-dark d-block my-2 font-size-h1">
                        {{tasks.task_ongoing}}
                        <i class="fa fa-tasks"></i>
                    </span>
                    <router-link :to="{name:'todoList'}" class="text-dark font-weight-bold font-size-h6">
                        OnGoing
                    </router-link>
                </b-card>
            </div>
            <div class="col-4">
                <b-card class="border-warning">
                    <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2 font-size-h1">
                        {{tasks.task_overdue}}
                        <i class="fa fa-tasks"></i>
                    </span>
                    <router-link :to="{name:'todoList'}" class="text-warning font-weight-bold font-size-h6">
                        OverDue
                    </router-link>
                </b-card>
            </div>
            <div class="col-4">
                <b-card class="border-secondary">
                    <span class="svg-icon svg-icon-3x svg-icon-secondary d-block my-2 font-size-h1">
                        {{tasks.task_completed}}
                        <i class="fa fa-tasks"></i>
                    </span>
                    <router-link :to="{name:'todoList'}" class="text-secondary font-weight-bold font-size-h6">
                        Completed
                    </router-link>
                </b-card>
            </div>
        </div>
        <div class="row m-0 mt-5" v-if="vendors !== null">
            <div class="col-12">
                <h3>Vendors</h3>
            </div>
            <div class="col-4">
                <b-card class="border-info">
                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2 font-size-h1">
                        {{vendors}}
                        <i class="fa fa-tasks"></i>
                    </span>
                    <router-link :to="{name:'vendor'}" class="text-info font-weight-bold font-size-h6">
                        Total Vendors
                    </router-link>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    import {request} from "@/core/services/Request";
    import {getEventId} from "@/util/globalFunction";
    import Countdown from 'vuejs-countdown'
    import moment from "moment-timezone"

    export default {
        components: {
            Countdown
        },
        data() {
            return {
                tasks: null,
                guests: null,
                vendors: null,
                events: null,
            }
        },
        methods: {
            getEventCountDown(date) {
                return moment(date).format('MMMM DD, YYYY')
            },
            async getDetails(id) {
                try {
                    const response = await request({
                        url: `/dashboards/guests/count/box/${id}`,
                        method: 'post',
                    })

                    const {data} = response.data
                    this.tasks = data.tasks
                    this.guests = data.guests
                    this.vendors = data.vendor_count
                    this.events = data.event
                } catch (e) {

                }
            },
        },
        mounted() {
            if (getEventId()) {
                this.getDetails(getEventId());
            }
        },
    }
</script>
