<template>

  <div>
    <KTCard>
      <template v-slot:title>
        Bookings
        <p><small>Confirmed Bookings Graph</small></p>
      </template>
      <template v-slot:body>
        <apexchart
          ref="bookingChart"
          :options="chartOptions"
          :series="series"
          class="card-rounded-bottom"
          height="300px"
          type="bar"
        ></apexchart>
      </template>
    </KTCard>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {request} from "@/core/services/Request";
import dayjs from 'dayjs';
import KTCard from "@/view/content/Card";

export default {
  name: "Booking",
  components: {KTCard},

  props: {
    revenue_chart_data: {
      type: Object,
      default: () => {
        return {
          keys: [],
          values: []
        }
      }
    }
  },
  data() {
    return {
      chartOptions: {},
      series: [],
      labels: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  async mounted() {


    this.chartOptions = {
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      series: [
        {
          name: "Revenues",
          data: []
        }
      ],
      plotOptions: {
        bar: {
          borderRadius: 7,
          horizontal: false,
          columnWidth: ["20%"],
          endingShape: "rounded",
          barHeight: "20%"
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },
        labels: {
          show: true,
          style: {
            colors: this.layoutConfig("colors.gray.gray-700"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toLocaleString('en-IN', {
              maximumFractionDigits: 0,
              style: 'currency',
              currency: 'INR'
            });
          },
          show: true,
          style: {
            colors: this.layoutConfig("colors.gray.gray-700"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function (val) {
            return val.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            });
          }
        }
      },
      colors: ["#001170"],
    };


    await this.loadBookingData()
  },
  watch: {
    revenue_chart_data: {
      handler: function () {
        this.loadBookingData();
      },
      deep: true
    }
  },
  methods: {

    async loadBookingData() {

      this.series = [
        {
          name: "Amount",
          data: this.revenue_chart_data.values
        }
      ];

      console.log(this.revenue_chart_data.keys)
      this.chartOptions.xaxis.categories = this.revenue_chart_data.keys

      await this.$nextTick();

      this.$refs.bookingChart.refresh();
    },
  }
};
</script>
