<template>

  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0 mt-5">
      <!--begin::Chart-->

      <div v-if="!data.length" class="emptyData">
        <h3>No Data available</h3>
      </div>
      <div class="chartBody" v-show="data.length > 0" ref="chartdiv">
      </div>
      <!--            {{data}}-->
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_theme from "@amcharts/amcharts4/themes/spiritedaway";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_theme);

export default {
  name: "Widget2",
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    title: {
      type: String,
      required: true
    },
    filtersApplied: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data() {
    return {
      chart: undefined
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  watch: {
    data() {
      if (this.chart && this.data && this.data.length > 0) {
        this.chart.data = this.data;
        this.chart.invalidateRawData();
      }
    },
  },
  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);

    chart.paddingRight = 20;
    chart.data = this.data || [];

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.radiusValue = "value";
    series.dataFields.category = "label";
    series.slices.template.cornerRadius = 6;
    series.colors.step = 3;

    series.hiddenState.properties.endAngle = -90;

    chart.legend = new am4charts.Legend();

    this.chart = chart;
  },
}
</script>

<style scoped>
.chartBody {
  width: 100%;
  height: 500px;
}

.emptyData {
  text-align: center;
  font-size: 2.2rem;
  margin-top: 200px;
  height: 250px;
}
</style>
