<template>
    <b-container fluid class="p-0">
        <b-row class="p-0">
            <b-card>
                <div class="row mb-5">
                    <b-col sm="2">
                        <b-form-group
                            label="Type Of Events"
                        >
                            <treeselect
                                id="event_id"
                                v-model="filters.event_id"
                                :options="dropdowns.events"
                                @input="getDetails"
                                :disabled="global.pendingRequests > 0"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="2">
                        <b-form-group
                            label="Properties"
                        >
                            <treeselect
                                id="property_id"
                                v-model="filters.property_id"
                                :options="_.map(this.getPropertyList, (i) => {
                                    return {
                                        id: i.id,
                                        label: i.name
                                    }
                                })"
                                @input="getDetails"
                                :disabled="global.pendingRequests > 0"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="2">
                        <b-form-group
                            label="Users"
                        >
                            <treeselect
                                id="user_id"
                                v-model="filters.user_id"
                                :options="dropdowns.users"
                                @input="getDetails"
                                :disabled="global.pendingRequests > 0"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group
                            label="Start Date">
                            <b-form-datepicker
                                :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                today-button
                                reset-button
                                close-button
                                v-model="filters.start_date"
                                @input="getDetails"
                                :disabled="global.pendingRequests > 0"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group
                            label="End Date">
                            <b-form-datepicker
                                :disabled="!filters.end_date || global.pendingRequests > 0"
                                :date-disabled-fn="dateDisabled"
                                :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                today-button
                                reset-button
                                close-button
                                v-model="filters.end_date"
                                @input="getDetails"
                            />
                        </b-form-group>
                    </b-col>
                </div>
                <b-row class="mb-5">
                    <b-col sm="4">
                        <b-card title="Total Event" class="bg-primary text-white">
                            <h4><i class="fa fa-list text-white"></i> {{eventCount}}</h4>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card title="Total Quotation" class="bg-success text-white">
                            <h4><i class="fa fa-list text-white"></i> {{quotationCount}}</h4>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card title="Total Booking" class="bg-dark text-white">
                            <h4><i class="fa fa-list text-white"></i> {{bookingCount}}</h4>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row class="mt-5 mb-5">
                    <b-col sm="4">
                        <b-card title="Total Collected Amount" class="bg-info text-white">
                            <h4><i class="fa fa-rupee-sign text-white"></i>
                                {{Number(totalCollectedCount).toLocaleString()}}
                            </h4>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card title="Total Lost" class="bg-danger text-white">
                            <h4><i class="fa fa-list text-white"></i> {{totalLostEventCount}}
                            </h4>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card title="Total Outstanding Amount" class="bg-warning">
                            <h4><i class="fa fa-rupee-sign text-dark"></i>
                                {{Number(totalOutStandingCount).toLocaleString()}}</h4>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row class="mt-5 mb-5">
                    <b-col sm="4">
                        <b-card title="Total FollowUp" class="border-danger text-danger">
                            <h4><i class="fa fa-list text-danger"></i> {{followUpCount}}</h4>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card title="Total Leads" class="border-info text-info">
                            <h4><i class="fa fa-list text-info"></i> {{leadCount}}</h4>
                        </b-card>
                    </b-col>
                    <b-col sm="4">
                        <b-card title="Total Brochures" class="border-dark">
                            <h4><i class="fa fa-list text-dark"></i> {{brochureCount}}</h4>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <apexchart type="line" height="350" :options="lineChart.chartOptions"
                                   :series="lineChart.series"></apexchart>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-card>
                            <div id="chart" v-if="pie_source_labels.length">
                                <apexchart height="257" type="pie" :options="chartOptions"
                                           :series="pie_source_number"></apexchart>
                            </div>
                            <div v-else>
                                <h4 class="text-center">No Date Available</h4>
                            </div>
                            <b-card-footer class="text-center">
                                <h4>Source</h4>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col sm="6">
                        <b-card>
                            <div id="chart" v-if="pie_drop_analytics_number.length">
                                <apexchart type="pie" :options="chartOptions1"
                                           :series="pie_drop_analytics_number"></apexchart>
                            </div>
                            <div v-else>
                                <h4 class="text-center">No Date Available</h4>
                            </div>

                            <b-card-footer class="text-center">
                                <h4>Drop Event Analytics</h4>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row class="mt-5">
                    <b-col sm="12">
                        <b-card>
                            <b-col sm="12" class-="mb-5">
                                <treeselect
                                    class="w-25 mb-5"
                                    v-model="filters.year"
                                    :options="years"
                                    @input="getDetails"
                                    :disabled="global.pendingRequests > 0"
                                />
                            </b-col>
                            <div>
                                <apexchart type="bar" height="350" :options="quotationBarChart.chartOptions"
                                           :series="quotationBarChart.series"></apexchart>
                            </div>
                            <b-card-footer class="text-center">
                                <h4>Quotations & Bookings</h4>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-row>
    </b-container>
</template>

<script>

    import {mapGetters, mapState} from "vuex"
    import {request} from "@/core/services/Request";
    import moment from "moment-timezone"
    import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";

    const FILTER_STATE = {
        event_id: null,
        user_id: null,
        property_id: null,
        start_date: null,
        end_date: null,
        year: null,
    }

    export default {
        mixins: [totalAmountMixin],
        data() {
            return {
                pie_source_number: [],
                pie_source_labels: [],
                pie_drop_analytics_number: [],
                pie_drop_analytics_labels: [],
                filters: {...FILTER_STATE},
                lineChart: {
                    series: [],
                    chartOptions: {
                        chart: {
                            type: 'line',
                            height: '500',
                            dropShadow: {
                                enabled: true,
                                color: '#000',
                                top: 18,
                                left: 7,
                                blur: 10,
                                opacity: 0.2
                            },
                            toolbar: {
                                show: false
                            }
                        },
                        colors: ['#77B6EA', '#545454', '#ff0000'],
                        dataLabels: {
                            enabled: true,
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        title: {
                            text: 'Quotation, Booking & Events Information',
                            align: 'left'
                        },
                        grid: {
                            borderColor: '#e7e7e7',
                            row: {
                                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                opacity: 0.5,
                            },
                        },
                        xaxis: {
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                            title: {
                                text: 'Month'
                            }
                        },
                        yaxis: {
                            title: {
                                text: 'Total'
                            },
                            labels: {
                                type: 'number',
                                formatter: function (value) {
                                    return parseInt(value);
                                }
                            },
                            max: null,
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'right',
                            floating: true,
                            offsetY: -25,
                            offsetX: -5
                        }
                    },
                },
                quotationBarChart: {
                    series: [{
                        data: []
                    }],
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            height: 350,
                            toolbar: {
                                show: false,
                            },
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 4,
                                horizontal: false,
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                            labels: {
                                type: 'datetime',
                            }
                        },
                        yaxis: {
                            labels: {
                                type: 'number',
                            }
                        }
                    },
                },
                dropAanalyticsPieChartData: [],
                followUpCount: 0,
                leadCount: 0,
                brochureCount: 0,
                quotationCount: 0,
                bookingCount: 0,
                eventCount: 0,
                totalLostEventCount: 0,
                totalCollectedCount: 0,
                totalOutStandingCount: 0,
                qBarChart: [],
                bBarChart: [],
                dropdowns: {
                    events: [],
                    users: [],
                },
            }
        },
        mounted() {
            this.getDetails()
            this.getUsers()
            this.getEventType()
        },
        methods: {
            dateDisabled(ymd, date) {
                var myCurrentDate = new Date(date);
                var myPastDate = new Date(myCurrentDate);
                myPastDate.setDate(myPastDate.getDate() + 1);

                return (myPastDate < new Date(this.filters.from_date))
            },
            async getDetails() {
                try {
                    const response = await request({
                        url: `/dashboards/admin/count/box`,
                        method: 'post',
                        data: {...this.filters},
                    })

                    const {data} = response.data

                    const {drop_analytics, sources} = data.pie_charts
                    this.dropAanalyticsPieChartData = drop_analytics

                    this.pie_source_number = _.map(sources, (i) => i.count)
                    this.pie_source_labels = _.map(sources, (i) => i.source_name)

                    this.pie_drop_analytics_number = _.map(drop_analytics, (i) => i.count)
                    this.pie_drop_analytics_labels = _.map(drop_analytics, (i) => i.title)


                    const {total_leads, total_follow_up, brochure_count, quotation_count, booking_count, event_count, lost_event_count, total_collected_amount, total_outstanding_amount} = data.count
                    this.leadCount = total_leads
                    this.followUpCount = total_follow_up
                    this.brochureCount = brochure_count
                    this.quotationCount = quotation_count
                    this.bookingCount = booking_count
                    this.eventCount = event_count
                    this.totalLostEventCount = lost_event_count
                    this.totalCollectedCount = total_collected_amount
                    let total = 0
                    _.map(total_outstanding_amount, (i) => {
                        total += parseFloat(this.totalEventAmount(i.events).total) + parseFloat(this.totalRoomAmount(i.rooms).total)
                    })
                    this.totalOutStandingCount = total - total_collected_amount
                    const {quotations, bookings, events} = data.line_charts

                    const allQuotationMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    const allBookingMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    const allEventsMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                    const allBarQuotationMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    const allBarBookingMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                    this.qBarChart = _.filter(data.bar_charts, (i) => i.type === 'default')
                    this.bBarChart = _.filter(data.bar_charts, (i) => i.type === 'booking')

                    _.filter(this.lineChart.chartOptions.xaxis.categories, (i, index) => {
                        allQuotationMonthViseData[index] = _.filter(quotations, (q) => moment(q.dates).format("MMM") === i).length
                        allBookingMonthViseData[index] = _.filter(bookings, (q) => moment(q.dates).format("MMM") === i).length
                        allEventsMonthViseData[index] = (_.filter(events, (q) => moment(q.dates).format("MMM") === i).length)
                        allBarQuotationMonthViseData[index] = _.map(_.filter(this.qBarChart, (q) => moment(q.dates).format("MMM") === i), (k) => {
                            return parseFloat(this.totalEventAmount(k.events).total) + parseFloat(this.totalRoomAmount(k.rooms).total)
                        })
                        allBarBookingMonthViseData[index] = _.map(_.filter(this.bBarChart, (q) => moment(q.dates).format("MMM") === i), (k) => {
                            return parseFloat(this.totalEventAmount(k.events).total) + parseFloat(this.totalRoomAmount(k.rooms).total)
                        })
                    })


                    //bar charts
                    this.quotationBarChart.series = [
                        {
                            name: "Quotations",
                            data: _.map(allBarQuotationMonthViseData, (t) => _.sum(t)),
                        },
                        {
                            name: "Bookings",
                            data: _.map(allBarBookingMonthViseData, (t) => _.sum(t)),
                        }
                    ]

                    this.lineChart.series = [
                        {
                            name: "Quotation",
                            data: allQuotationMonthViseData
                        },
                        {
                            name: "Events",
                            data: allEventsMonthViseData
                        },
                        {
                            name: "Bookings",
                            data: allBookingMonthViseData
                        },
                    ]

                    this.lineChart.chartOptions.yaxis.max = (_.sortBy(_.uniq(_.concat(allBookingMonthViseData, _.concat(allQuotationMonthViseData, allEventsMonthViseData)))).reverse() + 1)

                } catch (e) {

                }
            },
            async getEventType() {
                try {
                    const response = await request({
                        url: '/dropdowns/event/type',
                        method: 'post',
                    })

                    const {data} = response.data
                    this.dropdowns.events = _.map(data, (i) => {
                        return {
                            id: i,
                            label: i,
                        }
                    })
                } catch (e) {

                }
            },
            async getUsers() {
                try {
                    const response = await request({
                        url: '/dropdowns/users',
                        method: 'post',
                    })

                    const {data} = response.data

                    this.dropdowns.users = _.map(data, (i) => {
                        return {
                            id: i.id,
                            label: i.name,
                        }
                    })
                } catch (e) {

                }
            },
        },
        computed: {
            years() {
                const allYear = Array.from({length: new Date().getFullYear() - 2000}, (value, index) => 2020 + index);
                return allYear.map((item) => {
                    return {
                        id: item,
                        label: item,
                    }
                })
            },
            chartOptions: function () {
                return {
                    labels: this.pie_source_labels,
                }
            },
            chartOptions1: function () {
                return {
                    labels: this.pie_drop_analytics_labels,
                }
            },
            ...mapState([
                'global',
            ]),
            ...mapGetters({getPropertyList: 'getPropertyList'}),
        }
    }
</script>
